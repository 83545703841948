<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Reduccion de Cuotas - Editar</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/reduccion-cuotas/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Crédito:</label>
                    <v-select disabled placeholder="Seleccione un crédito" class="w-100" :filterable="false" label="text" v-model="credit"></v-select>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input readonly class="text-center" type="text" v-model="credit_fee_reduction.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Seleccione un código</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="credit_fee_reduction.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input type="date" class="text-center" v-model="credit_fee_reduction.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Cuota:">
                    <b-form-input disabled type="text" class="text-center" v-model="credit_fee_reduction.quota_number"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_fee_reduction.balance"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="credit_fee_reduction.total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label=".">
                    <b-button :disabled="credit_fee_reduction.annexes == ''" type="button" variant="warning" @click="OpenAnnexes" class="form-control">Ver Documento Adjunto</b-button>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Doc. Adjunto:">
                    <b-form-file  accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="credit_fee_reduction.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>


               
                <b-col md="12" >
                  <div class="alert alert-dark" role="alert">
                    <strong>CRONOGRAMA DE ṔAGO</strong> 
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="3%" class="text-center">#</th>
                          <th width="9%" class="text-center">Fecha</th>
                          <th width="7%" class="text-center">Cuota</th>
                          <th width="7%" class="text-center">Amortización</th>
                          <th width="7%" class="text-center">Capital</th>
                          <th width="7%" class="text-center">Interes</th>
                          <th width="7%" class="text-center">Saldo <br> Capital </th>
                          
                      
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in credit_fee_reduction.credits_details" :key="it">
                        <tr v-if="item.state == 1">
                          <td class="text-center">{{ item.quota_number }}</td>
                          <td class="text-center"> {{ item.date  }}</td>
                          <td class="text-right"> {{ item.quota  }}</td>
                          <td class="text-right"> {{ item.amortization  }}</td>
                          <td class="text-right"> {{ item.capital_balance  }}</td>
                          <td class="text-right"> {{ item.interest  }}</td>
                          <td class="text-right"> {{ item.balance  }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
            
               

               

                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button class="form-control" @click="OpenVoucher" type="button" variant="warning">Imprimir Recibo</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentAdd",
  props: ["id_credit_fee_reduction"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CreditFeeReduction',
      role: 3,
      credit_fee_reduction: {
          id_credit_fee_reduction:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_credit:'',
          id_credit_detail:'',
          code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          annexes:'',
          annexes_change:'',
          observation:'',
          quota:'0.00',
          total:'0.00',
          amortization:'0.00',
          dues:'0',
          state:1,
          quota_number : '',
          balance : '',
          interest : '',

          credits_details : [],


          total_max:'',
          dues_max:'',
      },
      file:null,
      modules:[],
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      credit: null,
      clients: [],
      errors: {
        id_client: false,
        payment_method: false,
        date: false,
        total: false,
        id_credit:false,
        id_credit_detail: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewCreditFeeReduction();
  },
  methods: {
    ViewCreditFeeReduction,
    EditCreditFeeReduction,
    Validate,
    onFileChange,
    OpenAnnexes,
    OpenVoucher,
   
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function onFileChange(e) {
  this.credit_fee_reduction.annexes_change = e.target.files[0];
}

function OpenAnnexes() {
  let me = this;
  let url = me.url_base + this.credit_fee_reduction.annexes;
  window.open(url,'_blank');
}


function ViewCreditFeeReduction() {
  let me = this;
  let id_credit_fee_reduction = je.decrypt(this.id_credit_fee_reduction);
  let url = me.url_base + "credit-fee-reduction/view/"+id_credit_fee_reduction;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.credit = {value:response.data.result.credit_fee_reduction.id_credit, text :response.data.result.credit_fee_reduction.credit }
          me.credit_fee_reduction.id_credit_fee_reduction = response.data.result.credit_fee_reduction.id_credit_fee_reduction;
          me.credit_fee_reduction.id_client = response.data.result.credit_fee_reduction.id_client;
          me.credit_fee_reduction.id_user = response.data.result.credit_fee_reduction.id_user;
          me.credit_fee_reduction.id_branch_office = response.data.result.credit_fee_reduction.id_branch_office;
          me.credit_fee_reduction.id_credit = response.data.result.credit_fee_reduction.id_credit;
          me.credit_fee_reduction.id_credit_detail = response.data.result.credit_fee_reduction.id_credit_detail;
          me.credit_fee_reduction.code = response.data.result.credit_fee_reduction.code;
          me.credit_fee_reduction.payment_method = response.data.result.credit_fee_reduction.payment_method;
          me.credit_fee_reduction.date = response.data.result.credit_fee_reduction.date;
          me.credit_fee_reduction.observation = response.data.result.credit_fee_reduction.observation;
          me.credit_fee_reduction.total = response.data.result.credit_fee_reduction.total;
          me.credit_fee_reduction.state = response.data.result.credit_fee_reduction.state;
          me.credit_fee_reduction.quota_number  = response.data.result.credit_fee_reduction.quota_number;
          me.credit_fee_reduction.balance  = response.data.result.credit_fee_reduction.balance;
          me.credit_fee_reduction.interest  = response.data.result.credit_fee_reduction.interest;
          me.credit_fee_reduction.quota  = response.data.result.credit_fee_reduction.quota;
          me.credit_fee_reduction.amortization  = response.data.result.credit_fee_reduction.amortization;
          me.credit_fee_reduction.credit_code  = response.data.result.credit_fee_reduction.credit_code;
          me.credit_fee_reduction.credits_details = response.data.result.credit_fee_reduction_detail;
      }
    });
}

function EditCreditFeeReduction() {
  let me = this;
  let url = me.url_base + "credit-fee-reduction/edit";
  me.isLoading = true;

  let data = new FormData();
  data.append("id_credit_fee_reduction", this.credit_fee_reduction.id_credit_fee_reduction);
  data.append("payment_method", this.credit_fee_reduction.payment_method);
  data.append("observation", this.credit_fee_reduction.observation);
  data.append("annexes", this.credit_fee_reduction.annexes_change);
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.credit_fee_reduction.annexes = response.data.result.annexes
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_credit = this.credit_fee_reduction.id_credit.length == 0 ? true : false;
  this.errors.payment_method = this.credit_fee_reduction.payment_method.length == 0 ? true : false;

  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
 
  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar la reducción de cuota del crédito?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditCreditFeeReduction();
    }
  });
}

function OpenVoucher() {
  let me = this;
  let url = me.url_base + "credit-fee-reduction-sales-of-receip/"+ this.credit_fee_reduction.id_credit_fee_reduction;
  var ventana1 = window.open(url, '_blank');
}
</script>
